<template>
  <div class="order-detail-supplier">
    <v-app>
      <v-container fluid>
        <v-card class="pt-8 mt-6">
          <v-app-bar class="navbar" app height="50px">
            <div class="header-title">
              <div class="d-flex align-center">
                <v-btn
                  icon
                  small
                  dark
                  class="mr-2 btn-prev"
                  @click.prevent="$router.back()"
                >
                  <v-icon>mdi-arrow-left</v-icon>
                </v-btn>
                <h1>รายละเอียด</h1>
              </div>
            </div>
          </v-app-bar>

          <v-container fluid>

                <div class="card-order-list">
                  <div>
                    <h4>
                      หมายเลขคำสั่งซื้อ :
                      {{
                        this.$store.getters.getOrderDetails
                          .purchaseRequisitionNo
                      }}
                    </h4>
                    <h4>
                      สถานะการสั่งซื้อ :
                      <span style="color: #0288d1" class="status-order-list">{{
                        this.$store.getters.getOrderDetails
                          .purchaseRequisitionStatus
                      }}</span>
                    </h4>
                    <h5 class="font-weight-regular">
                      วันที่สั่งซื้อ :
                      {{
                        this.$store.getters.getOrderDetails.createDate
                          | dateFormat
                      }}
                    </h5>
                  </div>
                  <v-divider class="mb-2 mt-2"></v-divider>
                  <!-- <div class="detail-school">
                    <h4>ผู้สั่งซื้อ</h4>
                    <p class="mb-1">
                      ชื่อ-นามสกุล :
                      {{ this.$store.getters.getOrderDetails.school.name }} - {{this.$store.getters.getOrderDetails.kitchenType}}
                    </p>
                    <p class="mb-1">
                      ที่อยู่ :
                      {{ this.$store.getters.getOrderDetails.school.address }}
                      {{ this.$store.getters.getOrderDetails.school.province }}
                      {{ this.$store.getters.getOrderDetails.school.postcode }}
                    </p>
                    <p class="mb-1">
                      ติดต่อ :
                      {{
                        this.$store.getters.getOrderDetails.school.shippingPhone
                      }}
                    </p>
                  </div>
                  <v-divider class="mb-2 mt-2"></v-divider> -->
                  <div class="wrapper-table mb-1 mt-2">
                      <table
                        class="order-table"
                        style="width: 100%; text-align: center"
                      >
                        <tr class="topic-order">
                          <th>Item</th>
                          <th>Price/Unit</th>
                          <th>Quantity</th>
                          <th>Total</th>
                          <th>Note</th>
                        </tr>
                        <tr
                          class="list-menu"
                          v-for="(item, index) in this.$store.getters
                            .getOrderDetails.goods"
                          :key="index"
                        >
                          <td class="text-left">{{ item.itemNameTh }}</td>
                          <td>{{ item.itemPrice }}/{{ item.itemUnit }}</td>
                          <td>{{ item.quantity }}
                            <!-- <div class="text-center">
                              <v-text-field
                                @change="
                                  inputUpdateQuantityPR(index, item.quantity)
                                "
                                autofocus
                                v-model="item.quantity"
                                class="input-edit-quantity"
                                :disabled="
                                  checkOpenUpdatePR == false ||
                                  item.id !== dataUpdateOrderPR.id
                                "
                                onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                              ></v-text-field>
                            </div> -->
                          </td>
                            <!-- <v-btn
                              @click.prevent="editUpdateQuantityPR(item.id)"
                              color="red"
                              outlined
                              x-small
                              >แก้ไข
                            </v-btn> -->
                          <td>{{(item.itemPrice * item.quantity) + (item.itemPrice * item.quantity)*(item.itemVat / 100)| currency2}}</td>
                          <td class="text-left">{{ item.note }}</td>
                        </tr>
                      </table>
                  </div>
                  <div class="text-center mt-2" v-if="this.$store.getters.getOrderDetails.purchaseRequisitionStatus == 'DRAFT'">
                    <v-btn @click.prevent="cancelOrderPR" color="red" outlined small
                    >ยกเลิกคำสั่งซื้อ</v-btn
                    >
                    <v-btn
                      @click.prevent="approveOrderPR"
                      color="primary"
                      small
                      class="ml-2"
                      >อนุมัติคำสั่งซื้อ</v-btn
                    >
                  </div>
                </div>


                

          </v-container>

          <!-- <div class="wrapper-update-pr" v-if="this.checkOpenUpdatePR == true">
            <v-card class="card-update-pr">
              <div>
                <div class="d-flex mb-1">
                  <div class="mr-3">
                    <img
                      height="80px"
                      width="85px"
                      :src="this.dataUpdateOrderPR.imagePath"
                      alt="Image Goods"
                    />
                  </div>
                  <div style="width: 100%">
                    <p class="mb-1">{{ this.dataUpdateOrderPR.name }}</p>
                    <div class="mt-0 d-flex align-center mt-4 mb-2">
                      <v-btn
                        color="green"
                        fab
                        x-small
                        outlined
                        @click.prevent="decQuantityUpdatePR"
                      >
                        <v-icon>mdi-minus</v-icon>
                      </v-btn>
                      <h2 class="mx-4">
                        {{ this.dataUpdateOrderPR.quantity }}
                      </h2>
                      <v-btn
                        class="white--text"
                        color="green"
                        fab
                        x-small
                        @click.prevent="addQuantityUpdatePR"
                      >
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>
                    </div>
                  </div>
                </div>
                <div class="text-center mt-4">
                  <v-btn
                    @click.prevent="closeUpdateOrderPR()"
                    small
                    outlined
                    color="red"
                    >ยกเลิก</v-btn
                  >
                  <v-btn
                    small
                    color="primary"
                    @click.prevent="updateOrderPR()"
                    class="ml-5"
                  >
                    บันทึก
                  </v-btn>
                </div>
              </div>
            </v-card>
          </div> -->
        </v-card>
      </v-container>
    </v-app>
  </div>
</template>

<script>
import moment from "moment";
import orderSupplier from "../services/orderSupplier.service";
import Swal from "sweetalert2";
export default {
  name: "OrderDetail",
  data() {
    return {
      checkOpenUpdatePR: false,
      dataUpdateOrderPR: {
        id: "",
      },
    };
  },
  filters: {
    dateFormat: function (date) {
      return moment(date).format(`DD MMMM YYYY, h:mm:ss a.`);
    },
  },
  created() {
    console.log(this.$store.getters.getOrderDetails);
  },
  methods: {
    editUpdateQuantityPR(id) {
      this.checkOpenUpdatePR = true;
      this.dataUpdateOrderPR.id = id;
    },
    cancelUpdateQuantityPR() {
      this.checkOpenUpdatePR = false;
      this.dataUpdateOrderPR.id = "";
    },
    addQuantityUpdatePR() {
      this.dataUpdateOrderPR.quantity++;
    },
    decQuantityUpdatePR() {
      if (this.dataUpdateOrderPR.quantity > 1) {
        this.dataUpdateOrderPR.quantity--;
      }
    },
    inputUpdateQuantityPR(index, quantity) {
      this.$store.dispatch("setQuantityOrderDetails", { index, quantity });
      this.cancelUpdateQuantityPR();
    },
    // updateOrderPR() {
    //   let goods = [];
    //   this.$store.getters.getOrderDetails.goods.forEach((response) => {
    //     let good = {
    //       id: response.id,
    //       quantity: response.quantity,
    //     };
    //     goods.push(good);
    //   });
    //   console.log(goods);
    //   orderSupplier
    //     .updateOrderPR(this.$store.getters.getOrderDetails.id, { goods: goods })
    //     .then((res) => {
    //       console.log(res);
    //       Swal.fire({
    //         icon: "success",
    //         title: "บันทึกการแก้ไขสำเร็จ",
    //         showConfirmButton: false,
    //         timer: 2000,
    //       });
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // },
    cancelOrderPR() {
       Swal.fire({
        title: "ยกเลิกคำสั่งซื้อ?",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          orderSupplier
            .cancelOrderPR(this.$store.getters.getOrderDetails.id)
            .then((res) => {
              console.log(res);
              this.$store.dispatch('setCancelOrderSupplier')
              Swal.fire({
                icon: "success",
                title: "ยกเลิกคำสั่งซื้อสำเร็จ",
                showConfirmButton: false,
                timer: 2000,
              });
            })
            .catch((err) => {
              console.log(err);
              Swal.fire({
                icon: "error",
                title: `${err}`,
                showConfirmButton: false,
                timer: 2000,
              });
            });
        }
      })
    },
    approveOrderPR() {
      Swal.fire({
        title: "ยืนยันคำสั่งซื้อ?",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          let goods = [];
          this.$store.getters.getOrderDetails.goods.forEach((response) => {
            let good = {
              id: response.id,
              quantity: response.quantity,
            };
            goods.push(good);
          });
          orderSupplier
            .approveOrderPR(this.$store.getters.getOrderDetails.id, { goods: goods })
            .then((res) => {
              console.log(res);
              this.$store.dispatch('setApproveOrderSupplier')
              Swal.fire({
                icon: "success",
                title: "อนุมัติคำสั่งซื้อสำเร็จ",
                showConfirmButton: false,
                timer: 2000,
              });
            })
            .catch((err) => {
              console.log(err);
              Swal.fire({
                icon: "error",
                title: `${err}`,
                showConfirmButton: false,
                timer: 2000,
              });
            });
        }
      });
    },
  },
};
</script>

<style scoped>
.wrapper-table {
  width: 100%;
  position: relative;
  overflow: scroll;
}
.order-table {
  border-collapse: collapse;
  border-radius: 3px;
  width: 100%;
  background: rgba(255, 255, 255, 0.8);
}
.topic-order th {
  font-weight: 500;
  font-size: 11px !important;
  color: #56448d;
  background: #fff;
}
.topic-order th:first-child {
  border-top-left-radius: 3px;
}
.topic-order th:last-child {
  border-top-right-radius: 3px;
}
.list-menu td,
.topic-order th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px 4px;
  text-align: center;
  font-size: 12px;
  transition: 0.4s;
}
.list-menu td {
    padding: 5px 2px;
}
/* ******Table******* */
.navbar {
  position: absolute !important;
  width: 95%;
  top: -24px !important;
  left: 50% !important;
  transform: translateX(-50%) !important;
  background: #2e2253 !important;
  border-radius: 3px !important;
}
.header-title {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header-title h1 {
  color: #fff;
  font-weight: 400;
  font-size: 19px;
}

.wrapper-update-pr {
  width: 80%;
  position: fixed;
  top: 48%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}
.card-update-pr {
  width: 100%;
  padding: 16px 10px;
}

.input-edit-quantity {
  width: 30px;
  margin: 0 auto;
}
.detail-school p{
    font-size: 14px;
}
</style>