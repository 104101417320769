import axios from 'axios';
class OrderSupplierService {

  getOrderSupplierPR(uid) {
    return axios
      .get(`/orders/pr/suppliers/line/${uid}`)
      // .get(`/orders/pr/suppliers/line/U1c0a5652d34822e7a2101e25def8bc2e`)
      //.get(API_URL + '/orders')
      .then(response => {
        return response.data;
      });
  }

  updateOrderPR(idPR,data) {
    return axios
      .put('/orders/pr/' + idPR, data)
      .then(response => {
        return response.data
      })
  }

  approveOrderPR(idPR,data) {
    return axios
      .post('/orders/pr/approval/' + idPR, data)
      .then(response => {
        return response.data
      })
  }

  cancelOrderPR(idPR) {
    return axios.post('/orders/pr/reject/' + idPR)
            .then(response => {
              return response.data
            })
  }
}

export default new OrderSupplierService();